import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./BusinessFilter.library.scss"

// Import component specific styles.
import styles from "./BusinessFilter.module.scss"

const formatNameForUrl = (name) => {
  name = name.replace(/\s+/g, '-').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  return name
}


const BusinessFilter = props => {
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  const outerElement = useRef()
  const combinedCategory = 'All Businesses'

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handleClick = event => {
    if (!outerElement.current.contains(event.target)) {
      setIsDropdownActive(false)
    }
  }

  return (
    <GridContainer>
      <div className={styles.businessFilterBlock} >
        <div className={styles.dropdown + " dropdown"} ref={outerElement}>
          <button
            className={isDropdownActive ? styles.dropdownTrigger + " dropdown-trigger " + styles.active : styles.dropdownTrigger + " dropdown-trigger"}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            aria-label="View business filter options"
            onClick={() => isDropdownActive ? setIsDropdownActive(false) : setIsDropdownActive(true)}
          >
            <div
              className={styles.dropdownButton + " button"}
            >
              <span className={styles.dropdownLabel}>{props.current}</span>
              <span className={styles.icon + " icon is-small"} role="presentation"></span>
            </div>
          </button>
          <div
            id="dropdown-menu"
            role="menu"
            className={isDropdownActive
              ? styles.dropdownMenu +  " dropdown-menu " + styles.dropdownIsActive
              : styles.dropdownMenu + " dropdown-menu"}
          >
            <div className={styles.dropdownContent +" dropdown-content"}>
              <ul className={styles.linksList} >
                <li className={styles.linksListItem}>
                  <Link
                    to="/local-business"
                    className={styles.dropdownItem + " dropdown-item"}
                    aria-label={'Filter business listing to show All Businesses'}
                    onClick={() => setIsDropdownActive(false)}
                    >
                    {combinedCategory}
                  </Link>
                </li>
                {props.links.map((link, index) => {
                  return (
                    <li className={styles.linksListItem} key={link.categoryName + index}>
                      <Link
                        to={'/local-business/' + formatNameForUrl(link.categoryName)}
                        className={styles.dropdownItem + " dropdown-item"}
                        aria-label={'Filter business listing to show ' + link.categoryName}
                        onClick={() => setIsDropdownActive(false)}
                      >
                        {link.categoryName}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </GridContainer>
  )
}
BusinessFilter.propTypes = {
  links: PropTypes.array,
  current: PropTypes.string,
}

BusinessFilter.defaultProps = {
  links: [],
  current: ``,
}

export default BusinessFilter
