import React from 'react'
import Layout from '../components/layout/Layout/Layout'
import { graphql } from 'gatsby'
import BusinessListings from '../components/BusinessListing/BusinessListings'
import BusinessFilter from '../components/BusinessFilter/BusinessFilter'
import HeroBanner from '../components/HeroBanner/HeroBanner'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import GridContainer from '../components/layout/GridContainer/GridContainer'

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query ($categoryName: String!) {
    allDatoCmsLocalBusiness(
      filter: {categories: {elemMatch: {
        categoryName: {eq: $categoryName}
      }}}
    )
    {
      nodes {
        ...BusinessListing
      }
    }
    allDatoCmsBusinessCategory {
      nodes {
        categoryName
      }
    }
  }
`

const Business = (props) => {
  return (
    <Layout
      metaTitle={"Local Businesses: " + props.pageContext.categoryName}
      metaDescription={"List of " + props.pageContext.categoryName + " local businesses in selsey."}
      metaImage={null}
      twitterCard={null}
      pathname={props.uri}
    >
      <HeroBanner title={"Local Businesses: " + props.pageContext.categoryName} />
      <GridContainer>
        <Breadcrumb url={props.uri} />
      </GridContainer>
      <BusinessFilter current={props.pageContext.categoryName} links={props.data.allDatoCmsBusinessCategory.nodes}/>
      <BusinessListings listings={props.data.allDatoCmsLocalBusiness.nodes} />
    </Layout>
  )
}

export default Business
