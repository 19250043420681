import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import { FiChevronLeft } from "react-icons/fi";

// Import necessary Bulma CSS Framework components.
import "./Breadcrumb.library.scss"

// Import component specific styles.
import styles from "./Breadcrumb.module.scss"

const createLinksArray = (url) => {
  let urlSections = url.split('/')
  let linksArray = [{ "text": 'Home', "url": '/' }];


  for(let i = 0; i < urlSections.length; i++) {
    let part = urlSections[i];
    if(part.length !== 0) {
      let text = part.replace(/-/g, ' ')
      text = capitalizeWords(text);
      let link = '/' + urlSections.slice( 0, i + 1 ).join('/');
      linksArray.push({ "text": text, "url": link });
    }
  }
  return linksArray
}

const capitalizeWords = text => {
  let words = text.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }
  return words.join(' ')
}

const getClassName = (links, i, props) => {
  if (i === 0) {
    return styles.homeButton
  } if (i === links.length - 2) {
    if (props.isBusiness === true) {
      return styles.hidden
    } else {
      return styles.parent
    }
  } if (i === 1 && props.isBusiness === false) {
    return styles.hidden
  }
}

const Breadcrumb = props => {
  const links = createLinksArray(props.url)
  return (
    <>
      { links.length > 2 &&
        <nav className={styles.breadcrumbs} aria-label="breadcrumbs">
          <ul>
          {links.map((link, i) => {
            if (i < links.length - 1 && link.text.length !== 0) {
              return (
                <li className={getClassName(links, i, props)} >
                  { i > 0 &&
                    <span className={styles.separator}><FiChevronLeft/></span>
                  }
                  <Link to={link.url} aria-label={'Navigate to the ' + link.text + ' page'}>{link.text}</Link>
                </li>
              )
            } else {
              return (
                <li className={styles.isActive}>
                  { i > 0 &&
                    <span className={styles.separator}><FiChevronLeft/></span>
                  }
                  {link.text}
                </li>
              )
            }
          })}
          </ul>
        </nav>
      }
    </>
  )
}

Breadcrumb.propTypes = {
  url: PropTypes.string,
  isBusiness: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  url: '',
  isBusiness: false,
}

export default Breadcrumb
