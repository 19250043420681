import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import { Link } from "gatsby"
import { FiChevronRight } from "react-icons/fi";
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./BusinessListing.library.scss"

// Import component specific styles.
import styles from "./BusinessListing.module.scss"

const BusinessListingWrapper = props => {
  return (
    <GridContainer>
      <div className={styles.businessListingWrapper}>
        <div className={styles.leftInfo}>
          { props.imageSrc && props.imageAlt && (
            <Link to={`/${props.listingLink}`} aria-label={"View " + props.title} tabIndex="-1">
              <button className={styles.buttonWrapper}>
                <Img className={styles.image} fixed={props.imageSrc} title={props.imageTitle} alt={props.imageAlt} />
              </button>
            </Link>
          )}
        </div>
        <div className={styles.middleInfo}>
          <Link to={`/${props.listingLink}`} aria-label={"View " + props.title} tabIndex="-1">
            <button className={styles.buttonWrapper}>
              <h2 className={styles.title + " h2Heavy"}>{props.title}</h2>
            </button>
          </Link>
          <p className={styles.openingTimes}>{props.openingTimes}</p>
        </div>
        <div className={styles.rightInfo}>
          <p className={styles.description}>{props.description}</p>
          <Link className={styles.chevron} aria-label={"View " + props.title} to={`/${props.listingLink}`} tabIndex="-1">
            <button className={styles.buttonWrapper}>
              <FiChevronRight/>
            </button>
          </Link>
        </div>
      </div>
    </GridContainer>
  )
}

BusinessListingWrapper.propTypes = {
  imageSrc: PropTypes.array,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
  title: PropTypes.string,
  openingTimes: PropTypes.string,
  description: PropTypes.string,
  listingLink: PropTypes.string,
}

BusinessListingWrapper.defaultProps = {
  imageSrc: [],
  imageAlt: ``,
  imageTitle: ``,
  title: ``,
  openingTimes: ``,
  description: ``,
  listingLink: ``,
}

export default BusinessListingWrapper
