import React from 'react'
import BusinessListingWrapper from './BusinessListing'

const BusinessListings = (props) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  let currentDate = new Date()
  let currentDay = weekday[currentDate.getDay()]
  return (
    props.listings.map((businessListing, index) => {
      const todaysTimes = (businessListing.hoursOfBusiness.find(function getTodaysTimes(day) {
        return day.day === currentDay
      }))
      const times = todaysTimes
        ? 'Open today '
          + String(todaysTimes.openingHour).padStart(2,'0')
          + ':'
          + String(todaysTimes.openingMinute).padStart(2,'0')
          + ' - '
          + String(todaysTimes.closingHour).padStart(2,'0')
          + ':'
          + String(todaysTimes.closingMinute).padStart(2,'0')
        : ''
        let imageSources = [];
        if (businessListing.imageUsedForMobileListings) {
          imageSources = [
            businessListing.imageUsedForMobileListings.fixed,
            {
              ...businessListing.imageUsedForWidescreenListings.fixed,
              media: `(min-width: 1216px)`,
            },
            {
              ...businessListing.imageUsedForDesktopListings.fixed,
              media: `(min-width: 1024px)`,
            },
            {
              ...businessListing.imageUsedForTabletListings.fixed,
              media: `(min-width: 769px)`,
            },
          ]
        }
      return (
        <BusinessListingWrapper
          key={index}
          imageSrc={imageSources.length ? imageSources : null}
          imageAlt={businessListing.imageUsedForListings ? businessListing.imageUsedForListings.alt : null}
          imageTitle={businessListing.imageUsedForListings ? businessListing.imageUsedForListings.title : null}
          title={businessListing.title}
          openingTimes={times}
          description={businessListing.descriptionUsedForListings}
          listingLink={businessListing.slug}
        />
      )
    })
  )
}

export default BusinessListings
